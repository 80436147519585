@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add the custom animations here */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-slideInFromLeft {
  animation: slideInFromLeft 0.5s ease-out;
}

.animate-slideInFromRight {
  animation: slideInFromRight 0.5s ease-out;
}

.animate-fadeInUp {
  animation: fadeInUp 0.5s ease-out;
}

.animate-fadeIn {
  animation: fadeIn 1s ease-out;
}
